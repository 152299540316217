/* Header CSS */
.headerMenu {
  font-size: 14px;
}

/* End of Header CSS */

#industry-dropdown, #tools-dropdown {
  color: #3F4A5F !important;
}

.wt_loader .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 50% !important;
  padding: 15px 12px 0px 15px !important;
}

.template_ui_toggle .css-ueukts-MuiButtonBase-root-MuiToggleButton-root {
  padding: 4px !important;
  margin-top: 2px !important;
}


.app_list {
  overflow-y: auto !important;
  scrollbar-color: #9EA4B5 #e4e4e4;
  transition: scrollbar-color 0.3s ease-out;
  scrollbar-width: thin;
}

.app_list::-webkit-scrollbar {
  width: 15px;
}

.app_list::-webkit-scrollbar-track {
  border-radius: 100px;
}

.app_list::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #F0F2F8 !important;
}