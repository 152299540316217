.pricing_calculator .him_BOX-SHADOW {
    box-shadow: rgb(0 0 0 / 25%) 1px 1px 6px;

}

.btn-group button {
    border: 1.5px solid #00A82D;
    color: gray;
    padding: 12px 24px;
    cursor: pointer;
    float: left;
    background: white;

}

.btn-group button:not(:last-child) {
    border-right: none;
}

.pricing_calculator .btn-group button {
    border: 1.5px solid #00A82D;
    color: gray;
    padding: 12px 24px;
    cursor: pointer;
    float: left;
    background: white;

}

.btn-group button {
    border: 1.5px solid #00A82D;
    color: gray;
    padding: 12px 24px;
    cursor: pointer;
    float: left;
    background: white;
}

.btn-group button:not(:last-child) {
    border-right: none;
}

.pricing_calculator .btn-group button:not(:last-child) {
    border-right: none;
}

.pricing_calculator .btn-group button:hover {
    background-color: #EFFFED;
}


@media only screen and (max-width: 780px) {

    .pricing_calculator .him_MarginLeft {
        margin-left: 72px !important;
    }

    .pricing_calculator .him_MarginLefT {
        margin-left: 107px !important;
    }

    .pricing_calculator .him_MarginLEfT {
        margin-left: 138px !important;
    }

    .pricing_calculator .him_BOX-SHADOW {
        padding: 16px !important;
    }

    .pricing_calculator .btn-group button {
        padding: 12px 25px !important;
    }
}