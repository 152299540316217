.tips .items-lottie {
    width: 50px !important;
    height: 40px !important;
}

.pricing-home .accordion-button:not(.collapsed) {
    color: #616874 !important;
    background-color: #F0F2F8 !important;
    box-shadow: none !important;
}

.pricing-home .accordion-item:first-of-type {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.pricing-home .accordion-item {
    background-color: none !important;
    border: 0px !important;
}

.pricing-home .accordion-body {
    padding: 0px 20px !important;
}


@media (min-width: 360px) and (max-width: 767.98px) {
    .pricing {
        margin: 0px !important;
    }

    .pricing .top-bar h1 {
        font-size: 24px !important;
    }

    .pricing .top-bar h5 {
        font-size: 15px !important;
    }

    .pricing .main-pricing {
        margin-right: -44px !important;
    }

    .pricing .main-pricing .price-topbar .text-center {
        padding: 0px !important;
        margin: 0px !important;
    }

    .pricing .main-pricing .price-topbar .pop {
        padding: 0px 5px 0px 5px !important;
        margin: 0px 0px -100px -8px !important;
        font-size: 13px !important;
    }

    .pricing .main-pricing .price-topbar .name0 {
        font-size: 20px !important;
    }

    .pricing .main-pricing .price-topbar .name1 {
        font-size: 15px !important;
    }

    .pricing .main-pricing .price-topbar .name2 {
        font-size: 15px !important;
    }

    .pricing .main-pricing .price-topbar .name3 {
        font-size: 12px !important;
        position: relative !important;
        top: -25px !important
    }

    .pricing .main-pricing .price-topbar .name4 {
        font-size: 7px !important;
        position: relative !important;
        top: 22px !important
    }

    .pricing .main-pricing .price-topbar .price {
        font-size: 12px !important;
    }

    .pricing .main-pricing .price-topbar .price3 {
        font-size: 12px !important;
        position: relative !important;
        top: -25px !important
    }

    .pricing .main-pricing .price-topbar .price4 {
        font-size: 12px !important;
        position: relative !important;
        top: 24px !important
    }

    .pricing .main-pricing .price-topbar .cut_price2 {
        font-size: 12px !important;
        position: relative !important;
        top: -6px !important
    }

    .pricing .main-pricing .price-topbar .cut_price3 {
        font-size: 12px !important;
        position: relative !important;
        top: -28px !important
    }

    .pricing .main-pricing .price-topbar .cut_price4 {
        font-size: 12px !important;
        position: relative !important;
        top: 20px !important
    }

    .pricing .main-pricing .accordion {
        margin-right: 0px !important;
    }

    .pricing .main-pricing .accordion .accordion-item .accordion-header button {
        font-size: 13px !important;
    }

    .pricing .main-pricing .accordion .row span {
        font-size: 12px !important;
    }

    .pricing .main-pricing .accordion .row .MuiSvgIcon-root {
        font-size: 12px !important;
    }

    .pricing .main-pricing .accordion .row .text-center span {
        font-size: 9px !important;
    }

    .planTypeUI {
        padding: 0px !important;
        margin: 0px !important;
    }

    .planTypeUI .row {
        padding: 0px !important;
        margin: 0px !important;
    }

    .planTypeUI .row .pTag {
        font-size: 14px !important;
        padding: 0px !important;
        margin-left: -21px !important;
        margin-right: -57px !important;
    }

    .planTypeUI .row .pTag span {
        font-size: 10px !important;
        padding: 0px 6px 0px 6px !important;
        margin: 0px !important;
    }

    .price_comparsion {
        padding: 0px !important;
        margin: 0px !important;
    }

    .price_comparsion .row {
        padding: 0px !important;
        margin: 10px !important;
    }

    /* .price_comparsion .colOne {
         margin-left: -43px !important;
     }

     .price_comparsion .colTwo {
         margin-right: 16px !important;
     }
     .price_comparsion .colThree{
         margin-right: 16px !important;
     }

     .price_comparsion .colFive {
         margin-right: -40px !important;
     } */

    .price_comparsion p {
        font-size: 10px !important;
    }

}

body input[type=checkbox] {
    accent-color: #00A82D !important;
}

body input[type=radio] {
    accent-color: #00A82D !important;
}


















/* Himanshu CSS */
/* Integration css */
.him_boxshadow {
    box-shadow: rgb(0 0 0 / 25%) 1px 1px 4px;
    min-height: 280px;
    max-height: 300px;
    position: relative;
}

@media only screen and (max-width: 768px) {
    .him_fs {
        font-size: 18px !important;
    }

    .him_fsx {
        font-size: 16px !important;
    }

    .him_wt {
        width: 320px !important;
    }
}

/* End of Integration css */

@media only screen and (max-width: 768px) {
    .him_FSize {
        font-size: 31px !important;
    }

    .him_FSIZE {
        font-size: 16px !important;
    }

    .him_fontSIZE {
        font-size: 22px !important;
    }

    .him_FONTSIZE {
        font-size: 23px !important;
    }

    .him_FONTsize {
        font-size: 17px !important;
    }

    .him_MARGIN {
        margin: 0 !important;
    }

    .him_mtt {
        margin-top: 70px !important;
    }

    .him_mar_tOP {
        margin-top: 35px !important;
    }

    .him_MT {
        margin-top: -35px !important;
    }
}

/* End of Himanshu CSS */




.him_Boxsed:hover {
    box-shadow: 0px 0px 6px #00a82d !important;
}

.him_WIDTH:focus {
    width: 19% !important;
}

.our_story_img_hover:hover {
    transform: scale(1.05)
}

@media only screen and (min-width: 360px) and (max-width: 768px) {

    .him_flowroot {
        display: flow-root !important;
    }

    .him_sizing {
        font-size: 22px !important;
    }

    .him_Sizing {
        font-size: 14px !important;
    }

    .him_sizedepen {
        font-size: 18px !important;
    }

    .him_Paddig {
        padding: 35px !important;
    }

    .him_WIDTH {
        width: 150px !important;
        padding-bottom: 50px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {

    .him_Paddig {
        padding: 0 !important;
    }

    .him_sizing {
        font-size: 24px !important;
    }
}