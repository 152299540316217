.removespinner input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#numberInputText input::-webkit-inner-spin-button,
#numberInputText input::-webkit-outer-spin-button {
  display: none;
}

.ripple-btn{
	position: relative;
	padding: 6px 22px;
	color: #fff;
	font-size: 16px;
	background: #00a82d;
	overflow: hidden;
	border-radius: px;
	cursor: pointer;
}

.btns {
	padding: 6px 22px;
	border: none;
	border-radius: 5px;
	background: #00a82d;
	color: #fff;
	font-size: 16px;
	outline: none;

	/* We need this to position
		span inside button */
	position: relative;
	overflow: hidden;
	box-shadow: 6px 7px 40px 4px
	#dadada;
}

.btns ::before{
	content: 'Copied';
	position: absolute;
	top: -50px;
	right: 0px;
	background: #00a82d;
	color: #fff;
	padding: 8px 10px;
	border-radius: 20px;
	font-size: 15px;
	display: none;
}

.btns ::after{
	content: '';
	position: absolute;
	top: -20px;
	right: 25px;
	width: 10px;
	height: 10px;
	background: #00a82d;
	transform: rotate(45deg);
	display: none;
}

.btns.active ::before,
.btns.active ::after{
	display: block;
}


.btns :hover{
	background: #00a82d;
	color: #fff;
}

	
