.minimizedwidget_bconcontainerbodyright__2843b{
    flex-direction:row-reverse;
    align-items:flex-end
}
.minimizedwidget_bconcontainerbodyleft__lZ8fK,
.minimizedwidget_bconcontainerbodyright__2843b{
    margin:0;overflow:hidden;
    display:flex;
    height:-webkit-fit-content;height:-moz-fit-content;
    height:fit-content;
    padding:20px
}
.minimizedwidget_bconcontainerbodyleft__lZ8fK{
    align-items:flex-start;
    flex-direction:row
}
.minimizedwidget_bconwelcomemessagecontainer__2DkWr{
    position:relative;
    z-index:9999;
    border-radius:.5rem;
    background-color:#fff;
    color:#333;
    box-shadow:0 2px 6px 0 rgba(0,0,0,.2);
    padding:.5rem;
    margin:0 1rem .3rem;
    max-width:300px;
    font-size:20px;
    overflow-wrap:break-word
}
.minimizedwidget_welcomemessageleft__11EkM:before{
    left:2px;box-shadow:-2px 2px 2px 0 rgba(0,0,0,.1)
}
.minimizedwidget_welcomemessage__qKtNb:after,
.minimizedwidget_welcomemessageleft__11EkM:before{
    display:block;
    position:absolute;
    content:"";
    width:10px;
    height:10px;
    bottom:10px;
    background-color:#fff;
    transform:translateX(-6px) rotate(45deg);
    pointer-events:none
}
   
.minimizedwidget_welcomemessage__qKtNb:after{
    right:-10px;
    box-shadow:2px -2px 2px 0 rgba(0,0,0,.1)
}
        
.minimizedwidget_bconcontainer__2SbUO{
    font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
    font-size:14px;
    line-height:1.5;
    box-sizing:border-box;
    position:relative
}
        
.minimizedwidget_bconcontainer__2SbUO button{
    margin:0;
    padding:.9rem;
    outline:none;
    border:none;
    border-color:initial;
    -o-border-image:none;
    border-image:none;
    -o-border-image:initial;
    border-image:initial;
    box-sizing:border-box;
    position:relative;
    bottom:0;
    z-index:999;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    display:flex;
    font-size:17px;
    font-weight:500;
    align-items:center;
    justify-content:center;
    background-color:#527ceb;
    color:#fff;
    border-radius:30px;
    transition:background-color .2s linear 0s,transform .2s linear 0s;
    box-shadow:0 2px 6px 0 rgba(0,0,0,.1)
}
   
.minimizedwidget_notificationbadge__2uhsG{
    font-size:.875rem;
    line-height:1.25rem;
    position:absolute;
    border-radius:2rem;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    transform:translateY(-50%);
    background-color:red;
    color:#fff;
    z-index:1000;
    padding:0 .5rem;
    top:0;
    right:0;
    box-shadow:0 2px 6px 0 rgba(0,0,0,.1)
}

.conversation_container__VxPiX{
    display:grid;
    grid-template-rows:60px minmax(0,1fr) minmax(-webkit-max-content,auto);
    grid-template-rows:60px minmax(0,1fr) minmax(max-content,auto);
    border-radius:10px;
    box-shadow:0 5px 15px 0 rgba(0,0,0,.07450980392156863);
    background-color:#fff;
    width:365px;
    margin-left:auto;
    max-height:100%;
    min-height:100%
}@media (max-width:480px){.conversation_container__VxPiX{width:100%}
}

.conversation_messagescontainer__vGqzb{
    background-image:url(https://storage.googleapis.com/wt_resources/wtb_media/widget_bg1.png);
    min-height:500px;
    flex-direction: column;
    
}

.conversation_messages__1z5uG{
    display:flex;
    flex-direction:column;
    padding:0 .65rem 1rem;
    overflow-y:auto
}

.conversation_messagebubble__18PAE{
    border-top-right-radius:.75rem;
    border-bottom-right-radius:.75rem;
    border-bottom-left-radius:.75rem;
    --tw-shadow:0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);
    box-shadow:var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
    margin:60px 25px 25px 25px;
    display:inline-block;
    position:relative;
    min-width:25%;
    max-width:75%;
    height:auto;
    background-color:#fff
}
       
.conversation_triright__3MXBw:after{
    content:" ";
     position:absolute;
     width:0;
     height:0;
     left:-10px;
     right:auto;
     top:0;
     bottom:auto;
     border:22px solid transparent;
     border-top-color:#fff
}

.conversation_messagetext__2xcva{
    padding:14px;
    text-align:left;
    font-size:16px;
    line-height:1.6;
    min-width: 150px;
    max-width:300px;
    max-height:auto;
    overflow:hidden;
    overflow-wrap:break-word
}

       
.conversation_message-text__ANDr- p{
    -webkit-margin-before:0;
    -webkit-margin-after:0
}
        
.conversation_compose__2HFAW{
    width:100%;
    background-color:#f0f0f0
}
                
            
.conversation_compose-container__1I_AI{
    width:100%;
    display:flex;
    flex-flow:row nowrap;
    border-top:1px solid #e6ecef;
    padding-left:12px;
    padding-right:12px;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px}.conversation_compose__2HFAW textarea{height:60px;
    width:100%;
    background-color:transparent;
    -webkit-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text;
    border:none;
    resize:none;
    margin-bottom:5px;
    padding-top:22px;
    display:block;
    font-size:16px;
    flex:1;
    font-family:Lato,sans-serif!important
}
                
.conversation_compose__2HFAW a.conversation_send__1aEzM{
    opacity:.9;
    color:#919191;
    width:35px;
    height:35px;
    justify-self:flex-end;
    align-self:center
}